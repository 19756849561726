<template>
  <el-dialog
    :model-value="visible"
    :title="title"
    :width="780"
    destroy-on-close
    @open="open"
    @close="close"
  >
    <el-form ref="elFormRef" :label-width="80" :model="formData" :rules="formRules">
      <el-form-item label="岗位名称" prop="name">
        <el-input v-model="formData.name" placeholder="请输入岗位名称" maxlength="50" show-word-limit clearable />
      </el-form-item>
      <el-form-item label="岗位类型" prop="categoryIds">
        <el-cascader
          placeholder="请选择岗位类型"
          v-model="formData.categoryIds"
          :options="categoryOptions.filter(e => e.children.length > 0)"
          :props="{
            value: 'id',
            label: 'categoryName',
            expandTrigger: 'hover',
          }"
          filterable
          style="width: 276px"
        />
      </el-form-item>
      <el-form-item label="岗位描述" prop="description">
        <el-input type="textarea" v-model="formData.description" placeholder="岗位描述" :rows="8" maxlength="2000" show-word-limit />
      </el-form-item>
      <el-form-item label="薪资面议" prop="isSalaryNegotiable">
        <el-radio-group v-model="formData.isSalaryNegotiable">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="!formData.isSalaryNegotiable">
        <!-- 结算方式 -->
        <el-row>
          <el-col :span="6">
            <el-form-item label="结算方式" prop="settlementType">
              <el-select v-model="formData.settlementType" style="width: 108px;">
                <el-option v-for="item in settlementOptions" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="margin-left: 16px;">
            <el-form-item v-if="formData.settlementType === SETTLEMENT.MONTH" prop="monthSalarySystem" label-width="auto">
              <el-input v-model="formData.monthSalarySystem" style="width: 108px;">
                <template #suffix>薪</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 薪资范围 -->
        <el-form-item label="薪资范围" prop="salary">
          <el-input style="width: 108px;" v-model="formData.salary[0]" placeholder="下限">
            <template #suffix>{{ formData.salaryUnit }}</template>
          </el-input>
          <span style="margin: 0 3px;">-</span>
          <el-input style="width: 108px;" v-model="formData.salary[1]" placeholder="上限">
            <template #suffix>{{ formData.salaryUnit }}</template>
          </el-input>
        </el-form-item>
      </template>
      <!-- 工作地点 -->
      <el-form-item label="工作地点" prop="workLocationCodes">
        <div class="el-cascader--fix">
          <el-cascader
            :props="locationCascaderProps"
            v-model="formData.workLocationCodes"
            clearable
            filterable
            style="width: 276px"
          />
        </div>
      </el-form-item>
      <el-form-item v-if="type === 'add'" label="岗位状态" prop="status">
        <el-select v-model="formData.status">
          <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, reactive, computed, watchEffect, toRaw, getCurrentInstance } from 'vue'
import { JOB_STATUS, JOB_STATUS_CN, SETTLEMENT, SETTLEMENT_CN, SALARY_UNIT, SALARY_SCALE } from '../context'
import { getProvinceInfo, getCityInfo, getAreaInfo } from '@/api/shop'
import { last, omit } from 'lodash'
import { addJob } from '@/api/recruitment'

const props = defineProps({
  type: String,
  visible: Boolean,
  categoryOptions: Array,
  data: Object
})

const emit = defineEmits(['success', 'close'])

const title = computed(() => `${props.type === 'add' ? '添加' : '编辑'}岗位`)

const { proxy } = getCurrentInstance()

const elFormRef = ref()

const settlementOptions = Object.entries(SETTLEMENT_CN).map(([k, v]) => ({
  value: k,
  label: v
}))

const statusOptions = Object.entries(JOB_STATUS_CN).map(([k, v]) => ({
  value: k,
  label: v
}))

const locationMap = {}
const setLocation = ({code, name}) => locationMap[code] = name
const locationCascaderProps = {
  lazy: true,
  value: 'code',
  label: 'name',
  multiple: true,
  checkStrictly: true,
  expandTrigger: 'hover',
  async lazyLoad(node, resolve) {
    const { level } = node
    const { code } = node.data
    if (level === 0) {
      const { data } = await getProvinceInfo()
      data.forEach((v) => {
        v.disabled = !v.name.endsWith('市')
        setLocation(v)
      })
      resolve(data)
    } else if (level === 1) {
      const { data } = await getCityInfo({ code })
      data.forEach((v) => {
        v.disabled = v.name === '直辖市'
        setLocation(v)
      })
      resolve(data)
    } else {
      const { data } = await getAreaInfo({ code })
      data.forEach((v) => {
        v.leaf = level >= 2
        setLocation(v)
      })
      resolve(data)
    }
  },
}

const defaultSalaryFormData = {
  minSalary: '',
  maxSalary: '',
  monthSalarySystem: '12', // 月结薪资体系 12-99
  salaryUnit: SALARY_UNIT[SETTLEMENT.MONTH], // 薪资单位
  settlementType: SETTLEMENT.MONTH, // 结算方式
}

const defaultFormData = {
  name: '', // 岗位名称
  jobId: '', // 岗位ID
  categoryId: '', // 岗位类型ID
  categoryIds: [], // 岗位类型ID
  description: '', // 岗位描述
  isSalaryNegotiable: false, // 是否薪资面议
  status: JOB_STATUS.UN_PUBLISHED, // 岗位状态
  workLocationCodes: [], // 工作地点的 code
  workLocations: [], // 工作地点
  salary: [], // 薪资范围
  ...defaultSalaryFormData,
}

const formData = reactive(structuredClone(defaultFormData))

const formRules = {
  name: [{ required: true, message: '请输入岗位名称' }],
  description: [{ required: true, message: '请输入岗位描述' }],
  categoryIds: [{ type: 'array', required: true, message: '请选择岗位类型' }],
  salary: [
    {
      type: 'array',
      required: true,
      len: 2,
      message: '请输入完整的薪资范围',
    },
    {
      validator(rule, [min, max], callback) {
        min = +min
        max = +max
        let msg = ''
        if (isNaN(min)) msg = '薪资下限必须是一个数字'
        else if (isNaN(max)) msg = '薪资上限必须是一个数字'
        else if (min <= 0) msg = '薪资下限必须大于0'
        else if (min > max) msg = '薪资上限不得小于薪资下限'
        callback(msg ? new Error(msg) : void 0)
      }
    }
  ],
  workLocationCodes: [
    {
      type: 'array',
      required: true,
      message: '请选择工作地点',
      trigger: 'change'
    }
  ],
  monthSalarySystem: [
    {
      validator(rule, value, callback) {
        if (12 <= value && value <= 99) callback()
        else callback(new Error('请输入12-99 以内的数字'))
      }
    }
  ],
  status: [{ required: true, message: '请选择岗位状态' }]
}

watchEffect(() => {
  formData.categoryId = last(formData.categoryIds)
})

watchEffect(() => {
  formData.salaryUnit = SALARY_UNIT[formData.settlementType]
  const scale = SALARY_SCALE[formData.settlementType]
  const [min, max] = formData.salary
  if (min) formData.minSalary = min * scale
  if (max) formData.maxSalary = max * scale
})

watchEffect(() => {
  formData.workLocations = formData.workLocationCodes.map(([provinceCode, cityCode, areaCode]) => {
    const obj = {}
    if (provinceCode) obj.provinceCode = provinceCode, obj.province = obj.locationName = locationMap[provinceCode]
    if (cityCode) obj.cityCode = cityCode, obj.city = obj.locationName = locationMap[cityCode]
    if (areaCode) obj.areaCode = areaCode, obj.area = obj.locationName = locationMap[areaCode]
    return obj
  })
})

function open() {
  setFormByData(props.type === 'edit' && props.data ? props.data : structuredClone(defaultFormData))
}

function setFormByData(data) {
  for (const key in data) {
    const val = data[key]
    if (key === 'id') {
      formData.jobId = val
    } else if (key === 'category') {
      formData.categoryIds = val.map(v => v.id)
    } else if (key === 'workLocations' && Array.isArray(val)) {
      const list = new Set()
      val.forEach(({provinceCode, cityCode, areaCode}) => {
        const arr = []
        if (provinceCode) arr.push(+provinceCode)
        if (cityCode) arr.push(+cityCode)
        if (areaCode) arr.push(+areaCode)
        list.add(arr.toString())
      })
      formData.workLocationCodes = [...list].map(e => e.split(',').map(v => +v))
    } else {
      formData[key] = val
    }
  }
  formData.salary = [data.minSalary, data.maxSalary].map(v => v / SALARY_SCALE[data.settlementType])
}

function confirm() {
  elFormRef.value.validate((valid) => {
    if (!valid) return
    
    let params = omit(toRaw(formData), 'categoryIds', 'workLocationCodes', 'salary')

    if (formData.isSalaryNegotiable) params = { ...params, ...defaultSalaryFormData }
    else if (formData.settlementType !== SETTLEMENT['MONTH']) params.monthSalarySystem = '12'
    
    addJob(params).then(res => {
      if (res.code !== 200) return
      proxy.$baseMessage(`${title.value}成功！`, 'success', 'vab-hey-message-success')
      emit('success')
    })
  })
}

function close() {
  emit('close')
}
</script>

<style lang="scss" scoped>
.el-cascader--fix :deep(.el-cascader__search-input:not(:only-child)) {
  display: none;
}
</style>